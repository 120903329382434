import request from "../utils/request";

export function getPlanTemplate() {
    return request({
        url: '/api/plan/template/list',
        method: 'get'
    })
}
export function getPlanTemplateInfo(data) {
    return request({
        url: '/api/plan/template/info',
        method: 'post',
        data
    })
}
export function createPlan(data) {
    return request({
        url: '/api/plan/create',
        method: 'post',
        data
    })
}
export function createPlanByOrderId(data) {
    return request({
        url: '/api/plan/commit',
        method: 'post',
        data
    })
}
export function createSms(data) {
    return request({
        url: '/api/sms/create',
        method: 'post',
        data
    })
}
export function sendSms(data) {
    return request({
        url: '/api/sms/send',
        method: 'post',
        data
    })
}
export function getPlanList() {
    return request({
        url: '/api/plan/list',
        method: 'get'
    })
}
export function getPlanInfo(data) {
    return request({
        url: '/api/plan/info',
        method: 'post',
        data
    })
}

export function getPlanSendList(data) {
    return request({
        url: '/api/plan/send/list',
        method: 'post',
        data
    })
}

export function getComboRechargeList() {
    return request({
        url: '/api/recharge/list',
        method: 'get'
    })
}
export function getMailBoxList() {
    return request({
        url: '/api/sms/list',
        method: 'get'
    })
}
export function getSmsChatList(data) {
    return request({
        url: '/api/sms/chat',
        method: 'post',
        data
    })
}