import request from "../utils/request";

export function createOneYuanOrder() {
    return request({
        url: '/api/order/create',
        method: 'post',
    })
}
export function createSmsOrder(data) {
    return request({
        url: '/api/order/createSmsOrder',
        method: 'post',
        data
    })
}
export function createRechargeOrder(data) {
    return request({
        url: '/api/order/recharge',
        method: 'post',
        data
    })
}
export function createPlanOrder(data) {
    return request({
        url: '/api/order/plan/create',
        method: 'post',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
        data
    })
}