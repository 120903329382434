<template xmlns="http://www.w3.org/1999/html">
  <span class="date">
    {{ getDate() }}
  </span>
  <div class="item">


    {{content}}
    <div class="icon"/>
  </div>

</template>

<script>
import dayjs from "dayjs";
export default {
  name: "SmsSendRecordItem",
  props: {
    date: String,
    content: String,
  },
  methods:{
    getDate(){
      return dayjs(this.date).format("YYYY/MM/DD HH:mm")
    }
  }

}
</script>

<style scoped>
.date{
  margin-top: 8px;
  height: 20px;
  font-size: 12px;
  line-height: 20px;
  color: #9ca3af;
}
.item{
  padding: 10px;
  background-color: white;
  border-radius: 10px;
  font-size: 12px;
  color: #292833;
  box-sizing: border-box;
  word-wrap:break-word;
  overflow: hidden;

  position: relative;
  word-break: break-all;
  width: 345px;
  min-height: 100px;
}
.icon{
  width: 64px;
  height: 64px;
  right: 10px;
  position: absolute;
  z-index: 2;
  background: url('../../assets/image/arrived.png') no-repeat;
  top:25%;
  background-size: 64px 64px;
}
</style>